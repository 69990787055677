.entrance {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.qr {
  position: relative;
  width: 65vw;
  height: 100vh;
  padding: calc((100vh - 65vw) / 2) 0;
  background-color: #000;
}

.qr-reader {
  width: 100%;
  height: 100%;
  z-index: 10;
}

.qr-message {
  position: absolute;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
  padding: 1em 2em;
  box-sizing: border-box;
  width: calc(100% - 160px);
  text-align: center;
  z-index: 100;
}

.panel {
  flex-grow: 1;
  width: 35vw;
  height: 100vh;
  background: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.panelContainer {
  flex-grow: 1;
  display: flex!important;
  align-items: center;
  justify-content: center;
}

.panelCircle {
  width: 100%;
}

.panelStatus {
  
}

